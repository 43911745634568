.newNPPModalRow {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.saveRNasNPPitem {
  width: 50%;
}

.saveRNasNPPitem label {
  width: 400px;
  margin-right: 10px;
}

.saveRNasNPPitem .ant-input-number {
  width: 100%;
}

.saveRNasNPPitem .ant-picker {
  width: 100%;
}
