.text-center2{
  
    text-align: center;

    padding: 8px;
}

.rollReserved2 {
  margin: 5px 0;
  
}