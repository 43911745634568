.ant-modal {

  top:320px;

  .anticon {
    vertical-align: 0 !important;
  }

  .ant-modal-body {
    font-family: 'Lato', sans-serif;
  }

}

