.ant-calendar-header {
  a {
    &:hover {
      cursor: pointer;
    }
  }
}

.ant-calendar-prev-year-btn,
.ant-calendar-month-panel-prev-year-btn,
.ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-decade-panel-prev-century-btn {
  &:after {
    content: '‹‹' !important;
  }
}

.ant-calendar-prev-month-btn {
  &:after {
    content: '‹' !important;
  }
}

.ant-calendar-next-month-btn {
  &:after {
    content: '›' !important;
  }
}

.ant-calendar-next-year-btn,
.ant-calendar-month-panel-next-year-btn,
.ant-calendar-year-panel-next-decade-btn,
.ant-calendar-decade-panel-next-century-btn {
  &:after {
    content: '››' !important;
  }
}

.ant-picker-date-panel table,
.ant-picker-date-panel table thead tr,
.ant-picker-date-panel table tbody tr,
.ant-picker-date-panel table thead tr th,
.ant-picker-date-panel table tbody tr td,
.ant-picker-month-panel table tbody tr td {
  border: 1px solid #d9d9d9;
}

.ant-picker-date-panel table thead tr {
  background-color: #216c97;
}

.ant-picker-date-panel table thead tr th {
  text-align: center;
  font-size: 1.05em;
  font-weight: bold;
  line-height: 2em !important;
}

.ant-badge-status-dot {
  display: none;
}

.ant-radio-button-wrapper {
  display: none;
}

.ant-badge-status-text {
  background-color: #3174ad;
  color: #ffffff;
  border-radius: 5px;
  font-size: 0.6rem;
  padding: 3px;
  margin: 0;
}

.events {
  line-height: 1.4rem;
  overflow-x: hidden;
}

.warning .ant-badge-status-text {
  color: #e3e200;
}

.error .ant-badge-status-text {
  color: #e52617;
  background-color: #ffffff;
}

.ant-badge-storekeeper-error .ant-badge-status-text{
  background-color: red;
  color: white;
  border-radius: 5px;
}

.ant-badge-storekeeper-success .ant-badge-status-text{
  background-color: rgb(54, 134, 54);
  color: white;
  border-radius: 5px;
}

.previousDay {
  background-color: #eeeeee;
}