label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.log {
  .card-wrapper {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 500px;

    @include media('<md') {
      min-width: 320px;
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      // color: #ffffff;
      color: #2c2638;
      font-weight: 100;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper {
    display: flex;
    height: 100%;

    .ant-card {
      min-width: 750px;
      width: 50%;

      @include media('<lg') {
        min-width: 100%;
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }

    .workOrderForm {
      width: 100%;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper-material {
    display: flex;
    height: 100%;

    .ant-card {
      min-width: 750px;
      width: 100%;

      @include media('<lg') {
        min-width: 100%;
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }

    .workOrderForm {
      width: 100%;
    }
  }
}

.machine-selector {
  .ant-select-selector {
    height: 50px !important;
    align-items: center !important;
    width: 130px !important;
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: flex-start;
  }

  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.ant-card {
  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: #ffffff;
    }
  }
}

.ant-card-head {
  // background-color: #4f66a0;
  // color: #ffffff;
  // color: #2c2638;
  color: #ffffff;
  // background-color: #ffffff;
  background-color: #216c97;
  text-transform: capitalize;

  .ant-card-head-title {
    //added
    text-transform: initial;
  }
}

.ant-picker-large {
  padding: 5px 11px 6px;
}

.panel-primary > .panel-heading {
  color: #ffffff;
  background-color: #216c97;
  border-color: #216c97;
}

.panel-heading .panel-title span {
  background-color: #216c97 !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.ant-col img {
  height: 180px !important;
  width: 180px !important;
}

.ant-table-cell img {
  height: 180px !important;
  min-width: 180px !important;
  width: 180px !important;
}

.ant-table-cell .qrCodeInTable {
  height: 50px !important;
  min-width: 50px !important;

  width: 50px !important;
}

.ant-table-cell .qrCodeInTable:hover {
  height: 180px !important;
  min-width: 180px !important;

  width: 180px !important;
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 400px) {
  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

.ant-form-item-label {
  min-width: 100px;
  // width: 33%;
}

.edit-product .ant-form-item-label {
  min-width: 140px;
  // width: 33%;
}

input,
select {
  font-size: 100% !important;
}

.subtitle {
  display: flex;
  color: #216c97;
  background-color: #d9d9d9;
  height: 20px;
  margin-bottom: 20px;
  padding: 16px;
  width: 35%;
  justify-content: center;
  align-items: center;
}

.materialField,
.colorField {
  margin-bottom: 0;
}

.workOrderForm .ant-input[disabled],
.workOrderForm .ant-picker-input > input[disabled] {
  color: #17202a;
}

.rollReserved {
  margin: 5px 0;
  border-bottom: 1px solid #ced4da;
  font-size: 13px;
}

.toolForm .ant-input[disabled],
.toolForm .ant-picker-input > input[disabled] {
  color: #17202a;
}

.materialField1 {
  margin-top: 10px;
}

.ant-row {
  // margin-left: -15px;
  //   margin-right: -15px;
  //   margin-bottom: 15px;
  margin: 0 10px 10px 0px;
}

@media screen and (max-width: 500px) {
  .mobileVersion {
    display: flex;
    flex-direction: column;
  }

  .subtitle {
    width: 100%;
    font-size: 0.9em;
  }

  .formatField {
    justify-content: flex-start !important;
  }

  .workOrderButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9em;
  }

  .secondButton {
    margin: 10px 0px;
  }

  .workOrderForm .ant-card-head-title {
    font-size: 1.05rem;
  }

  .materialField1 {
    margin-top: 0;
  }
}

.workOrderForm .ant-form-horizontal .ant-form-item-control {
  min-width: auto !important;
}

.productField {
  margin-left: 58px;
}

.companyField {
  margin-left: 78px;
}

.userField {
  margin-left: 15px;
}

.toolField {
  margin-left: 25px;
}

.creationDate .ant-form-item-control {
  flex-direction: row;
}

.label-width-reset {
  .ant-form-item-label {
    min-width: unset !important;
  }
}
