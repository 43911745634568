.ant-statistic-content-prefix {
    .anticon {
        vertical-align: baseline;
        position: relative;
        top: -0.2ex;
    }
}

// .ant-checkbox-checked {
//     .ant-checkbox-inner {
//         background-color: #259dab !important;
//         border-color: #259dab !important;
//     }
// }


.ant-checkbox-checked {
    .ant-checkbox-inner {
        background-color: #4f66a0 !important;
        border-color: #4f66a0 !important;
    }
}