form {
  .ant-input-group-wrapper {
    display: block;
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-input-group {
  font-size: 16px;

  >.ant-input {
    &:last-child {
      border-radius: 4px;
    }
  }

  .ant-calendar-picker-input {
    float: none;
  }
}

.ant-input-group-addon {
  padding-left: 0;
  border: none;
  border-radius: 0;

  &:first-child {
    background-color: transparent;
    color: inherit;
    display: block;
    font-size: 0.875em;
    font-weight: bold;
    width: 100% !important;
    margin-bottom: 0.5em;

    .form-horizontal & {
      width: 20% !important;
      text-align: right;
      display: table-cell;
      margin: 0;
    }
  }
}

.ant-input-lg {
  font-size: 14px;
}

.ant-select-selection__rendered {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
}

.ant-select-lg {
  .ant-select-selection__rendered {
    font-size: 14px;
  }
}

.ant-select-tree-switcher {
  .anticon {
    vertical-align: inherit;
  }
}

.ant-select-tree-node-content-wrapper {
  vertical-align: middle;
}

.ant-select-selection__choice__remove {
  .anticon {
    vertical-align: text-top;
  }
}

textarea {
  width: 100%;
  border-radius: 4px;
  resize: vertical;
  height: 140px;
  overflow: auto;
  flex: 1 1 auto;
  border: 1px solid #d9d9d9;
  padding: 0.75em 11px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Lato', sans-serif;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    border-color: #40a9ff !important;
    outline: none;
  }
}

.ant-radio-inner:after {
  background-color: #4f66a0 !important;
}

.ant-input-group>.ant-input:not(:first-child):not(:last-child) {
  border-radius: 4px;
}

.ant-card-head-title {
  text-align: left;
}

// .ant-card-head {
//   background-color: #464f63;
//   color: #fff;
//   text-transform: uppercase;
// }

.productionList {
  margin: 5px 5px 5px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ced4da;
  width: 100%;
  font-weight: bold;
}

.productionList:last-of-type {
  border: 0;
}

.listProduction,
.listProduction input,
.listProduction input:disabled {
  list-style-type: circle;
  color: #246997;
  text-transform: uppercase;
}

.closedWorkOrder {
  background-color: red;
  color: white;
  display: flex;
  justify-content: center;
  height: 40px;
  font-size: 1.2rem;
  padding: 5px;
}

.productionHeader {
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.totalHours {
  margin-top: 10px;
  color: #ffffff;
  background-color: rgb(33, 108, 151);
  padding: 10px 15px;
}

.verifiedWorkOrder {
  background-color: rgb(238, 255, 0);
  color: white;
  display: flex;
  justify-content: center;
  height: 40px;
  font-size: 1.2rem;
  padding: 5px;
}

.productionList input:disabled,
.materialView input:disabled,
.rollForm input:disabled {
  color: rgb(0, 0, 0);
}

.ant-row{
  margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;
}

@media only screen and(max-width: 575px) {
  .ant-form-item-label {
    margin-left: 0px;
  }

  div.ant-row {
    margin-left: 0px !important;
  }
}